import React, { Fragment, Component } from 'react'

import Layout from '@/layout'
import SEO from '@/seo'
import Container from '@/container'
import WidgetLoader from '@/widget-loader'
import { loadScript } from 'utils/common'

const Widget = ({ feedKey, articleKey, contestKey }) => (
  <WidgetLoader
    feedKey={feedKey}
    articleKey={articleKey}
    contestKey={contestKey}
  />
)

class HomePage extends Component {
  state = { scriptLoaded: false }

  componentDidMount() {
    loadScript(
      `choicely-widget`,
      `https://cdn.choicely.com/widget.js?t=${Date.now()}`,
      () => this.setState({ scriptLoaded: true })
    )
  }

  render() {
    if (!this.state.scriptLoaded) {
      return null
    }

    return (
      <Fragment>
        <SEO title="Reina del Mundo Venezuela 2022" />
        <Layout>
          <Container>
            <Widget contestKey="329dbad4-2893-11ed-8f89-59d4da8541b6" />
          </Container>
        </Layout>
      </Fragment>
    )
  }
}

export default HomePage
